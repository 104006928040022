import React from 'react';
import {without} from 'lodash';
import {setup} from 'bem-cn';

import './TreeView.scss';

const treeViewSetup = setup({
    mod: '--'
});
const treeView = treeViewSetup('tree-view');

class TreeView extends React.PureComponent {


    constructor(props) {
        super(props);
        this.state = {
            items: [],
            mode: '',
            parentSelect: [],
            childSelect: {
                parent_index: null,
                child_index: null
            },
            defaultSelect: []
        };
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        const {data, mode, input} = this.props;
        const defaultSelect = Object.keys(data.filter(item => item.collapsed));

        if (input?.name === "periodFeature") {
            input.onChange({
                parent_index: input?.name === "periodFeature" ? true : defaultSelect,
                defaultSelect: input?.name === "periodFeature" ? ["0"] : defaultSelect,
                items: data
            });

        } else {
            input.onChange({
                defaultSelect: defaultSelect,
                items: data
            });
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log('componentDidUpdate',prevProps, prevState, snapshot);

    }

    handleClick(currentIndex) {
        const {input, mode, value, data} = this.props;
        const {childSelect} = value;
        let { items } = value;
        if (mode === 'manyNorm') {  // в первую очередь берем из нормализованных данных
            items = data;
        }

        const parent = items && items[currentIndex] ? items[currentIndex] : data[currentIndex];
        let elems = items ? items : data;

        if (mode === 'many' || mode === 'manyNorm') {
            const childSelectMany = childSelect && childSelect.parent_index
                && childSelect.parent_index.includes(currentIndex);

            if (childSelectMany) {
                let currentIdParent = items[currentIndex].id;
                let childSelectDel = childSelect && without(childSelect.parent_index, currentIndex)
                let defaultSelectDel = value && without(value.defaultSelect, currentIndex.toString());
                let parentIndexMany = value && value.parent_index && value.parent_index.filter(e => {
                    return e !== currentIdParent
                });

                input.onChange({
                    parent_index: parentIndexMany,
                    child_index: null,
                    childSelect: {
                        parent_index: childSelectDel,
                        child_index: null
                    },
                    items: elems,
                    defaultSelect: defaultSelectDel
                });
            } else {

                let defaultSelectMany = value.defaultSelect ? value.defaultSelect : [];
                defaultSelectMany.push(currentIndex.toString());
                let parentIndexMany = value.parent_index ? value.parent_index : [];
                parentIndexMany.push(parent.id);
                let childSelectParentIndex = childSelect && childSelect.parent_index ?
                    childSelect.parent_index : [];
                childSelectParentIndex.push(currentIndex);

                input.onChange({
                    parent_index: parentIndexMany,
                    child_index: null,
                    items: [
                        ...elems.slice(0, currentIndex),
                        {
                            ...elems[currentIndex],
                            collapsed: !elems[currentIndex].collapsed
                        },
                        ...elems.slice(currentIndex + 1),
                    ],
                    childSelect: {
                        parent_index: childSelectParentIndex,
                        child_index: null
                    },
                    defaultSelect: defaultSelectMany
                });
            }
        }
        if (mode === 'single') {
// 2022-12-20 В этом режиме добавлена возможность отмены выбора, без этого после выбора одного элемента нельзя вернуться назад
// к исходному состояению
            if ( value.defaultSelect.length == 0 ||
                 value.defaultSelect.length >0 && +value.defaultSelect[0] != currentIndex) {
                input.onChange({
                    parent_index: parent.id,
                    child_index: null,
                    items: [
                        ...elems.slice(0, currentIndex),
                        {
                            ...elems[currentIndex],
                            collapsed: !elems[currentIndex].collapsed
                        },
                        ...elems.slice(currentIndex + 1),
                    ],
                    childSelect: {
                        parent_index: currentIndex,
                        child_index: null
                    },
                    defaultSelect: [currentIndex.toString()]
                });
            }
            else {  // 2022-12-20 добавлено изменение сброс ранее выбранного
                input.onChange({
                    parent_index: null,
                    child_index: null,
                    items: [
                        ...elems.slice(0, currentIndex),
                        {
                            ...elems[currentIndex],
                            collapsed: !elems[currentIndex].collapsed
                        },
                        ...elems.slice(currentIndex + 1),
                    ],
                    childSelect: {
                        parent_index: null,
                        child_index: null
                    },
                    defaultSelect: []
                });

            }
        }
    }

    handleClickChild(parentIndex, childIndex) {
        const {input, value, data, mode} = this.props;
        const { childSelect} = value;
        let { items } = value;
        if (mode === 'manyNorm') {  // в первую очередь берем из нормализованных данных
            items = data;
        }
        const parent = items && items[parentIndex] ? items[parentIndex] : data[parentIndex];
        const child = parent && parent.children[childIndex];

        if (mode === 'single') {
            input.onChange({
                items: items,
                parent_index: parent.id,
                child_index: child.id,
                childSelect: {
                    parent_index: parentIndex,
                    child_index: childIndex
                },
                defaultSelect: [parentIndex.toString()]
            });
        }

        if (mode === 'many' || mode === 'manyNorm') {
            const childSelectMany = childSelect && childSelect.child_index
                && childSelect.child_index.includes(childIndex);
            if (childSelectMany) {
                let currentIdChildren = items[parentIndex].children[childIndex].id;
                let parentIndexMany = value && value.children && value.children.filter(e => {
                    return e !== currentIdChildren
                });
                let parent = value.parent_index ? value.parent_index : [];
                let childSelectDel = childSelect && without(childSelect.child_index, childIndex)
                let defaultSelectDel = value && value.defaultSelect;
                let childSelectParentIndex = childSelect && childSelect.parent_index ?
                    childSelect.parent_index : [];

                input.onChange({
                    parent_index: parent,
                    child_index: parentIndexMany,
                    childSelect: {
                        parent_index: childSelectParentIndex,
                        child_index: childSelectDel
                    },
                    items: items,
                    defaultSelect: defaultSelectDel
                });

            } else {
                let defaultSelectMany = value.defaultSelect ? value.defaultSelect : [];
                let parentIndexMany = value.parent_index ? value.parent_index : [];
                let childSelectParentIndex = childSelect && childSelect.parent_index ?
                    childSelect.parent_index : [];
                let childSelectChildIndex = childSelect && childSelect.child_index ?
                    childSelect.child_index : [];
                childSelectChildIndex.push(childIndex);

                let childIndexArrayId = value && value.child_index ? value.child_index : [];
                childIndexArrayId.push(child.id);

                input.onChange({
                    items: items,
                    parent_index: parentIndexMany,
                    child_index: childIndexArrayId,
                    childSelect: {
                        parent_index: childSelectParentIndex,
                        child_index: childSelectChildIndex
                    },
                    defaultSelect: defaultSelectMany
                });
            }


        }

    }

    render() {
        const {value, data, mode} = this.props;
        const {childSelect, items} = value;
        let array;
        if (mode === 'manyNorm') {  // в первую очередь берем из нормализованных данных
            array = data ? data : items;

        } else {
            array = items ? items : data;
        }
        return (
            <div className={treeView()}>
                {mode === 'single' && array && array.map((item, parentIndex) => {
                    let defaultSelect;
                    if (value === '') {
                        defaultSelect = [];
                    } else {
                        defaultSelect = value.defaultSelect
                    }
                    const isCheck = defaultSelect.indexOf(parentIndex.toString());
                    return (
                        <div key={parentIndex}>
                            <div className={treeView('item', {mod: isCheck ? '' : 'is-collapsed'})}
                                 onClick={this.handleClick.bind(this, parentIndex)}>
                                    <span className={treeView('label--parent')}>
                                        {item.name}
                                    </span>
                            </div>
                            {
                                item.collapsed && item.children && (
                                    item.children.map((child, childIndex) => {
                                        const isSelect = (childSelect.parent_index === parentIndex
                                            && childSelect.child_index === childIndex);
                                        return (
                                            <div key={childIndex} className={treeView('container')}>
                                                <div
                                                    className={treeView('container', 'item').state({check: isSelect ? '-is' : ''})}
                                                    onClick={this.handleClickChild.bind(this, parentIndex, childIndex)}
                                                >
                                                        <span className={treeView('label--child')}>
                                                            {child.name}
                                                        </span>
                                                </div>
                                            </div>
                                        )
                                    })
                                )
                            }
                        </div>
                    )
                })
                }

                {(mode === 'many' || mode === 'manyNorm') && array && array.map((item, parentIndex) => {
                    let defaultSelect;
                    if (value === '') {
                        defaultSelect = [];
                    } else {
                        defaultSelect = value.defaultSelect
                    }
                    const isCheck = defaultSelect.includes(parentIndex.toString());
                    return (
                        <div key={parentIndex}>
                            <div className={treeView('item', {mod: isCheck ? 'is-collapsed' : ''})}
                                 onClick={this.handleClick.bind(this, parentIndex)}>
                                    <span className={treeView('label--parent')}>
                                        {item.name}
                                    </span>
                            </div>
                            {
                                item.collapsed && item.children && (
                                    item.children.map((child, childIndex) => {
                                        let parentActive = childSelect && childSelect.parent_index && childSelect.parent_index.includes(parentIndex)
                                        let childActive = childSelect && childSelect.child_index && childSelect.child_index.includes(childIndex)
                                        const isSelect = (parentActive && childActive);
                                        return (
                                            <div key={childIndex} className={treeView('container')}>
                                                <div
                                                    className={treeView('container', 'item').state({check: isSelect ? '-is' : ''})}
                                                    onClick={this.handleClickChild.bind(this, parentIndex, childIndex)}
                                                >
                                                        <span className={treeView('label--child')}>
                                                            {child.name}
                                                        </span>
                                                </div>
                                            </div>
                                        )
                                    })
                                )
                            }
                        </div>
                    )
                })
                }
            </div>
        );
    }
}

export default TreeView;